<p-toast [baseZIndex]="1000"></p-toast>

<div class="h-screen flex align-content-center">
   <div class="w-4">
      <div class="p-4">
         <img src="assets/youskill-logo.png" style="width: 100px" alt="youskill" />
      </div>
      <div style="height: 60%" class="flex flex-wrap justify-content-center align-content-center">
         <form class="w-6" (ngSubmit)="onSubmit()" [formGroup]="loginForm">
            <p class="pb-3 font-bold text-lg">Login</p>

            <span class="my-4 p-float-label">
               <input class="w-full" pInputText id="username" formControlName="login" />
               <label htmlFor="username">Username</label>
            </span>

            <span class="my-4 p-float-label">
               <input class="w-full" pInputText type="password" id="password" formControlName="password" />
               <label htmlFor="password">Password</label>
            </span>

            <button class="my-4 w-full text-xs" pButton type="submit" label="Login"></button>
         </form>
      </div>
   </div>

   <div class="w-8 bg-blue-50 flex flex-wrap justify-content-center align-content-center gap-4">
      <img
         src="assets/login.svg"
         style="width: 550px"
         alt="Promuovi, sviluppa e aggiorna le competenze della tua azienda con serenità e senza stress!" />
      <h1 class="w-7">Promuovi, sviluppa e aggiorna le competenze della tua azienda con serenità e senza stress!</h1>
   </div>
</div>
