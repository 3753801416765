import { NgModule } from '@angular/core';
import { CommonKitModule } from '@app/app/common-kit.module';
import { ToastModule } from 'primeng/toast';
import { LoginRootingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { LoginService } from '@app/app/services/login.service';

@NgModule({
   declarations: [LoginComponent],
   imports: [LoginRootingModule, CommonKitModule, ToastModule],
   providers: [LoginService],
})
export class LoginModule {}
