<form #ngForm="ngForm" class="card" [formGroup]="userForm!" (submit)="submit()" novalidate>
   <div class="formgrid grid">
      <div class="field col">
         <label>Organizzazione</label>
         <p-dropdown
            formControlName="organization"
            styleClass="w-full"
            [options]="organizationList"
            [autoDisplayFirst]="false"
            optionLabel="name"
            optionValue="id"
            (onChange)="this.onOrganizationChange($event.value)"></p-dropdown>
      </div>
      <div class="field col">
         <label>Dipartimento</label>
         <p-dropdown
            formControlName="department"
            styleClass="w-full"
            [options]="departmentList"
            [autoDisplayFirst]="false"
            optionLabel="name"
            optionValue="id"></p-dropdown>
      </div>
   </div>
   <div class="formgrid grid">
      <div class="field col">
         <label>Firstname</label>
         <input formControlName="firstname" class="w-full" type="text" pInputText />
      </div>
      <div class="field col">
         <label>Lastname</label>
         <input formControlName="lastname" class="w-full" type="text" pInputText />
      </div>
   </div>
   <div class="formgrid grid">
      <div class="field col-6">
         <label>Email</label>
         <input formControlName="email" class="w-full" type="text" pInputText />
      </div>
      <div class="field col-3">
         <label>Password</label>
         <p-password formControlName="password" [toggleMask]="true"></p-password>
         <p-message
            *ngIf="ngForm.submitted && userForm?.hasError('invalidPasswordFormat')"
            severity="error"
            text="Invalid password format"></p-message>
         <p-message
            *ngIf="ngForm.submitted && userForm?.hasError('mismatch')"
            severity="error"
            text="Password mismatch"></p-message>
      </div>
      <div class="field col-3">
         <label>Confirm password</label>
         <p-password formControlName="confirmPassword" [feedback]="false"></p-password>
      </div>
   </div>
   <div class="formgrid grid">
      <div class="field col-6">
         <label>Username</label>
         <input formControlName="username" class="w-full" type="text" pInputText />
      </div>
      <div class="field col-3">
         <label>Ruolo</label>
         <p-multiSelect
            appendTo="body"
            styleClass="w-full"
            formControlName="roles"
            [options]="rolesList"
            optionLabel="label"
            optionValue="id"></p-multiSelect>
      </div>
      <div class="flex px-2 align-items-center gap-1">
         <p-checkbox formControlName="active" [binary]="true"></p-checkbox>
         <label>Active</label>
      </div>
   </div>

   <div class="text-right">
      <button type="submit" pButton pRipple label="Submit"></button>
   </div>
</form>
