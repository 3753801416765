import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { HeaderLeftDirective, HeaderRightDirective } from './components/app-template/header/header.directives';
import { DirectivesModule } from './directives/directives.module';
import { RolesListPipe } from './pipes/roles-list.pipe';
import { CachedRequestsService } from './services/cached-requests';
import { TreeModule } from 'primeng/tree';
import { ChartModule } from 'primeng/chart';
import { TabViewModule } from 'primeng/tabview';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollPanelModule } from 'primeng/scrollpanel';

const modules = [
   DirectivesModule,
   CommonModule,
   FormsModule,
   TableModule,
   DynamicDialogModule,
   ButtonModule,
   CheckboxModule,
   ConfirmDialogModule,
   InputTextModule,
   ReactiveFormsModule,
   PanelModule,
   PasswordModule,
   MessageModule,
   MessagesModule,
   AvatarModule,
   MenubarModule,
   DropdownModule,
   MultiSelectModule,
   ProgressBarModule,
   MenuModule,
   DialogModule,
   TreeModule,
   ChartModule,
   TabViewModule,
   DividerModule,
   OverlayPanelModule,
   ScrollPanelModule,
  ];

const declarations = [RolesListPipe, HeaderRightDirective, HeaderLeftDirective];

@NgModule({
   declarations: declarations,
   imports: modules,
   exports: [...modules, ...declarations],
   providers: [DialogService, ConfirmationService, CachedRequestsService],
})
export class CommonKitModule {}
